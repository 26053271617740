.loader {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid rgb(110, 110, 110);
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 