.overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #000;
    opacity: 0.1;
}
.covered{
    opacity: 1;
}
.modal{
    position: fixed;
    top: 18%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1000;
    max-height: 75%;
    overflow-y: auto;
    min-width: 260px;
}
.fade-in{
    opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.3s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.modal::-webkit-scrollbar-track {
    border: none;
    padding: 2px 0;
    background-color: #404040;
    border-radius: 10px;
}

.modal::-webkit-scrollbar {
    width: 10px;
}

.modal::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #737272;
    border: 1px solid #000;
}

#close-modal{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5em;
    cursor: pointer;
    color: #00ADEF;
    background-color: white;
    border-radius: 50%;
}
/**/
@media screen and (orientation:portrait) {
    @media (min-width:0px) and (max-width: 420px) { /* smartphones, iPhone, portrait 480x320 phones */ 
        .modal{

        }
    }
}
@media screen and (orientation:landscape) {
    @media (min-height:0px) and (max-height: 481px) { /* smartphones, iPhone, portrait 480x320 phones */ 
        .modal{
            max-height: 85%;
        }
    }
    @media (min-height:350px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */

    }
    @media (min-height:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */

    }
    @media (min-height:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 

    }
    @media (min-height:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 

    }
    @media (min-height:1025px) { /* big landscape tablets, laptops, and desktops */ 

    }
    @media (min-height:1281px) { /* hi-res laptops and desktops */ 

    } 
}
