$letterContainerSquareSide: 38px;

/*Loader*/
.loader-game{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    height: 100%;
}
/*Game Wrapper (for displaying background primarily)*/
#game-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-position: center;
}
/*Game*/
#game{
    width: 100%;
    max-width: 375px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    font-size: 16px;
    background-size: 50%;
}
/*Board*/
#board{
}
/*Rows*/
.row-container{
    display: flex;
    flex-direction: row;
}
/*Letter*/
.letter-container{
    border-color: #73adff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $letterContainerSquareSide;
    height: $letterContainerSquareSide;
    outline: 2px solid #fff;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 28px;
    color: white;
    background-color: #0074c2;
    position: relative;
    z-index: 0;
}
.letter-container > p{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 100;
}
@media screen and (max-width: 350px){
    $letterContainerSquareSide: 30px;
    .letter-container{
        width: $letterContainerSquareSide;
        height: $letterContainerSquareSide;
    }
}
.misplaced{
    border-radius: 50%;
    width: 90% !important;
    height: 90% !important;
    margin-right: 4%;
    margin-bottom: 2%;
}

/*Game logo*/
.game-logo-container{
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 100;
    max-height: 120px;
}
.game-logo-container img{
    width: 100%;
    height: auto;
}
/*Message*/
#msg{
    padding: 0.8em 2em;
    border-radius: 1em;
    background-color: #57001E;
    transition: opacity linear 1s;
    width: 100%;
    min-width: 230px;
}
@media (max-width: 350px){
    #msg {
        font-size: 13px;
    }
}
#claim-container{
    font-size: 1.1em;
}
.top-text{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 55px;
    font-size: 1em;
    max-width: 350px;
    margin: 0.3em 0;
}
.keyboard-container{
    width: 95%;
}
.keyboard-container .key{
    background-color: hsla(0, 0%, 0%, 0.2);
}
