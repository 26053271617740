#games-portal{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #fff;
    font-size: 20px;
}
@media (max-width: 400px){
    #games-portal {
        font-size: 14px;
    }
}
#games-portal .title{
    font-size: 1.4em;
    color: #0E4677;
    margin: 1em 0;
}
#games-portal .not-found{
    font-size: 1.2em;
    color: #fff;
    border-radius: 5px;
    background-color: #0E4677;
    width: fit-content;
    margin: 0 auto;
    padding: 0.5em;
}
#games-portal .games-list{
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    min-width: 275px;
    height: 100%;
    min-height: 300px;
}
#games-portal .game-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5em;
    height: fit-content;
    min-height: 90px;
    cursor: pointer;
}
#games-portal .game-card .game-card-logo{
    border-radius: 10px;
    width: 95%;
    max-width: 390px;
    height: 60%;
    min-height: 100px;
    background-size: 100% 100%;
}
#games-portal .game-card .game-card-text{
    display: flex;
    justify-content: center;
    white-space: normal;
    font-size: 1em;
    padding: 0.2em 0;
    color: black;
    width: 90%;
}
#games-portal .game-card .game-card-text p{
    margin: 0 2%;
    max-width: 50%;
    white-space: wrap;
    text-align: center;
}