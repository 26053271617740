$background: rgb(30, 11, 61);
$headerText: rgb(228, 245, 255);
#reset-pass .wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    flex-direction: column;
    background: linear-gradient(white, $headerText 90%, $background 99%);
    padding: 3vh 0 10vh 0;
}
#reset-pass .input-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3vh 0;
    text-align: center;
}
#reset-pass .input-wrap label{
    margin: 1vh 0;
}
#reset-pass button{
    padding: 1vh 1vw;
    margin: 5vh 0;
}