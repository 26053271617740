$background: rgb(30, 11, 61);
$headerText: rgb(228, 245, 255);
#forgot .wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    flex-direction: column;
    background: linear-gradient(white, $headerText 90%, $background 99%);
    padding: 3vh 0 10vh 0;
}
#forgot .input-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3vh 0;
    text-align: center;
}
#forgot .input-wrap label{
    margin: 1vh 0;
}
#forgot button{
    padding: 1vh 1vw;
    margin: 5vh 0;
}

@media only screen and (max-width: 550px){
    #forgot .fields{
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
    }
    #forgot .text-fields{
        width: 100%;
    }
    #forgot .input-wrap{
        display: flex;
        flex-direction: column;
    }
    #forgot .input-wrap input{
        width: 90%;
    }
    #forgot .input-wrap label{
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
    }
}