
#games .title-container{
    display: flex;
    align-items: center;
}
#games .title-container button{
    margin: 0 10px;
}
#games .title-container .msg{
    font-size: 0.8em;
    opacity: 1;
}
#games .title-container .msg .err{
    color: rgb(255, 146, 146);
}
#games .loader-games{
    margin: 10px auto;
    font-size: 30px;
}
/*Create Game Modal*/
#modal-create-game{
    font-size: 20px;
    background-color: rgb(138, 138, 138);
    width: 80vw;
    min-width: 270px;
    border-radius: 10px 0 0 10px;
}
#modal-create-game .modal-title{
    margin-bottom: 1em;
}
#modal-create-game section{
    margin-bottom: 1em;
}
.modal-section-title{
    font-size: 1.2em;
    margin-bottom: 0.2em;
    border-bottom: 2px solid #fff;
}
.modal-section-subtitle{
    font-size: 0.9em;
    margin-bottom: 0.5em;
}
#modal-create-game .input-wrap{
    margin: 0.3em 0;
}
#modal-create-game .input-wrap .wlabel{
    font-size: 0.8em;
    width: 30%;
    max-width: 170px;
}
#modal-create-game .input-wrap .input-wrap-input-element{
    width: 70%;
    max-width: 280px;
}
#modal-create-game .input-wrap .input-wrap-input-element input[type=text]{
    width: 100%;
    font-size: 0.8em;
}
#modal-create-game .input-wrap .input-wrap-input-element input[type=color]{
    width: 100px;
}
#modal-create-game .input-wrap .input-wrap-input-element textarea{
    width: 100%;
    font-size: 0.8em;
}
#modal-create-game .input-wrap .input-wrap-input-element select{
    width: 100%;
    font-size: 0.8em;
}
#modal-create-game .button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0 1em 0;
}
#modal-create-game .button-container button{
    text-transform: uppercase;
    margin: 0 1em;
}
#modal-create-game .modal-msg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 1em;
    height: 30px;
}
#modal-create-game .modal-msg .err{
    color: rgb(236, 87, 87);
}
/*Games list*/
#games .table-container{
    overflow-y: auto;
    font-size: 16px;
}
#games .table-container thead{
    background-color: rgb(110, 110, 110);
    border: none;
    font-size: 1em;
    color: #fff;
}
/*Element page*/
#element{
    border: 2px solid rgb(110, 110, 110);
    font-size: 20px;
    margin-top: 1em;
}
#element .icons-container{
    display: flex;
    height: 50px;
}
#element .subsection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
#element .subsection-title{
    font-size: 1.2em;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    border-bottom: 1px solid rgb(234, 234, 234);
    width: 100%;
    text-align: left;
}
#element .subsection-title .missing-data{
    font-size: 0.7em;
    color: rgb(255, 17, 17);
    text-transform: uppercase;
    vertical-align: middle;
}
#element .subsection-subtitle{
    font-size: 0.9em;
    margin-bottom: 1.5em;
    padding-left: 0.2em;
}
#element .subsection-content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0.2em;
    width: 100%;
}
#element .subsection-content .content-card{
    display: flex;
    flex-direction: column;
    width: 33%;
    margin: 0 2em 2em 0;
    font-size: 0.8em;
}
#element .subsection-content .content-card .content-card-label{
    font-size: 1em;
    color: #fff;
    padding: 5px;
    background-color: rgb(110, 110, 110);
    width: 100%;
    border-radius: 5px 5px 0 0;
}
#element .subsection-content .content-card .update-field-container{
    border: none;
    width: 100%;
}
#element .subsection-content .content-card .update-field-container .update-field{
    border: 1px solid rgb(234, 234, 234);
    text-align: center;
    color: rgb(60, 60, 60);
    width: 100%;
    border-radius: 0 0 5px 5px;
    padding: 5px;
    cursor: pointer;
}
#element .subsection-content .content-card .update-field-container .update-field:hover{
    background-color: rgb(235, 235, 235);
}
#element .subsection-content .content-card .update-field-container .update-field:focus{
    outline: none;
    background-color: inherit;
}
#element .subsection-content .content-card .update-field-container .image-field{
    max-width: 100%;
    min-height: 200px;
    max-height: 100px;
}
#element .subsection-content .content-card .update-field-container .image-field:hover{
    border: 2px dotted rgb(110, 110, 110);
    border-top: 0;
}

.hidden-file-input{
    position: absolute;
    opacity: 0;
    display: none;
    width: 100%;
}

#element .subsection-content .content-card .update-field-container audio{
    margin-top: 10px;
    width: 100%;
    font-size: 10px;
}
#element .subsection-content .content-card .update-field-container .audio-field{
    border-radius: 0 0 5px 5px;
    min-width: 100%;
    min-height: 35px;
}
#element .subsection-content .content-card .update-field-container .audio-field:hover{
    border: 2px dotted rgb(110, 110, 110);
    border-top: 0;
}

#element .subsection-content .content-card .update-field-container .radio-field{
    display: flex;
    align-items: center;
    justify-content: center;
}
#element .subsection-content .content-card .update-field-container .radio-field .radio-label-input{
    display: flex;
    align-items: center;
    margin: 0 1em;
}
#element .subsection-content .content-card .update-field-container .radio-field .radio-label-input label{
    line-height: 1px;
    margin: 0 0.2em;
}
#element .subsection-content .content-card .update-field-container .radio-field .radio-label-input input{
    cursor: pointer;
    width: 20px;
    height: 20px;
}
#element .subsection-content .content-card .update-field-container .loader-container{
    display: none;
    margin: 10px auto;
    font-size: 25px;
}
#element .remove{
    float: right;
    cursor: pointer;
    width: 100%;
    border: 1px solid #eaeaea;
    border-bottom: none;
    padding: 1px 0;
}
#element .remove:hover{
    color: rgb(255, 100, 100);
}