#register .pre-auth-body{
    display: flex;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
}
#register form{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: black;
    box-shadow: 0px 0px 5px 5px lightgray;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 0.8em;
    width: 100%;
    min-height: 415px;
}
#register .fields{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}
#register .text-fields{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-evenly;
    height: 100%;
}
#register .input-wrap{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 2vh 0;
}
#register .input-wrap label{
    width: 50%;
    padding: 0 0.5vw;
}
#register .form-title{
    margin: 0 0 4vh 0;
}
#register .required-field{
    font-size: 0.9em;
    text-align: center;
    margin: 1em 0;
}
#register .avatar-field span input[type=file]{
    position: absolute;
    width: 100%;
    opacity: 0;
    font-size: 0;
    cursor: pointer;
    height: 22px;
}
#register button[type=submit]{
    max-width: 150px;
    margin: 2em auto;
}
@media only screen and (max-width: 550px){
    #register .fields{
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
    }
    #register .text-fields{
        width: 100%;
    }
    #register .input-wrap{
        display: flex;
        flex-direction: column;
    }
    #register .input-wrap input{
        width: 90%;
    }
    #register .input-wrap label{
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
    }
}