html{
  height: 100%;
}
body {
  height: 100%;
}
#root{
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
table{
  width: 100%;
  border-collapse: collapse;
}
thead{
  border: 1px solid black;
}
tbody{
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
th, td{
  min-width: 75px;
  min-height: 50px;
  padding: 0.7rem;
  border-bottom: 1px solid black;
  text-align: center;
}
input{
  min-height: 35px;
  border: none;
  outline: none;
  border-color: transparent;
}
input[type="color" i]::-moz-color-swatch{
    border: none;
}
textarea{
  min-height: 35px;
  resize: none;
  text-align: left !important;
}
