#login{
}
#login form{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: black;
    width: fit-content;
    height: 60%;
    min-height: 300px;
    margin: 5% 0;
    box-shadow: 0px 0px 5px 5px lightgray;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 0.8em;
}
#login form .form-title{
    font-size: 1.1em;
}
#login .input-wrap{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
#login .input-wrap label{
    width: 45%;
    padding: 0 0.5vw;
}
#login .input-wrap input{
    font-size: 0.8em;
}
#login .msg{
    color: black;
    min-height: 20px;
}
