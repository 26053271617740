#logout{
    text-align: center;
    width: 100%;
    padding: 0.3rem 1rem;
    color: rgba(0, 0, 0, 0.616);
    border-radius: 0.2rem;
    background: rgb(214, 212, 212);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
#logout:hover{
    color: #fff;
}