#keyboard{
    width: 100%;
    margin: 20px auto;
}
.row{
    display: flex;
    justify-content: space-between;
    gap: 0.25em;
    width: 100%;
}
.row + .row {
    margin-top: 0.25em;
}
.key{
    font-size: 18px;
    display: inline-block;
    border: 1px solid #fff;
    border-radius: 5px;
    user-select: none;
    flex-grow: 1;
    flex-shrink: 0;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: monospace;
    cursor: pointer;
}
.key:hover{
    opacity: 0.8;
}

.enter-key{
    flex-grow: 2;
}

.key-correct{
    background: #e40029;
}

.key-incorrect{
    background: rgb(113, 113, 113);
}

.key-misplaced{
    background: #ffbd00;
}

@media (min-width:481px)  { /* smartphones, iPhone, portrait 480x320 phones */

}

@media screen and (orientation:portrait) {
    @media (min-width:0px) and (max-width: 520px) { /* smartphones, iPhone, portrait 480x320 phones */ 
        #keyboard{
            width: 90%;
        }
        .key{
            height: 30px;
            width: 20px;
            font-size: 16px;
        }
    }
}
@media screen and (orientation:landscape) {
    @media (min-height:0px) and (max-height: 481px) { /* smartphones, iPhone, portrait 480x320 phones */ 
        
    }
}