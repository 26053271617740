#stats{
}
#stats .title-container .msg{
    font-size: 0.8em;
    opacity: 1;
    margin-left: 10px;
}
#stats .title-container .msg .err{
    color: rgb(255, 146, 146);
}
#stats .loader-visitors{
    margin: 10px auto;
    font-size: 30px;
}
#stats .table-container{
    overflow-y: auto;
    font-size: 16px;
}
#stats .table-container thead{
    background-color: rgb(110, 110, 110);
    border: none;
    font-size: 1em;
    color: #fff;
}
#stats .table-row-data:hover{
    background-color: inherit;
}