/*GamePage*/
.game-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: auto;
    background: #0e0e0f;
    color: white;
}
/*Modal*/
.modal-container{
    color: white;
    padding: 30px 20px;
    margin: auto;
    background-color: #0E4679;
}
.modal-title{
    font-size: 2em;
    text-align: center;
    margin-bottom: 1em;
}
/*Instructions modal*/
#modal-instructions{
    font-size: 1.2em;
    line-height: 1.2em;
}
.correct-modal-bg{
    background-color: #D40C88;
}
.instr-row-example{
    display: flex;
    justify-content: center;
    margin: 1em auto;
}
.modal-title2{
    font-size: 1.5em;
    text-align: center;
    margin: 0.5em;
    color: #D40C88;
}
/*Stats modal*/
#modal-stats{
    font-size: 1.2em;
    line-height: 1.2em;
}
#modal-stats .modal-title{
    color: white;
}
// Game records
#modal-stats .game-record{
    display: flex;
    flex-direction: column;
    align-items: center;
}
#modal-stats .game-results{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}
#modal-stats .modal-title{
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #D40C88;
}
#modal-stats .modal-subtitle{
    font-size: 1.2em;
    margin-bottom: 15px;
    color: white;
}
#modal-stats .game-results .guesses-container{
    width: fit-content;
    margin: 0 auto;
}
#modal-stats .game-results .guesses-cards{
}
#modal-stats .game-results .guesses-cards .guess-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
#modal-stats .game-results .guesses-cards .guess-row .guess-card{
    width: 20px;
    height: 20px;
    margin: 1px;
}
#modal-stats .game-results .word-letters{
    display: flex;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 10px;
    gap: 6px;
}
#modal-stats .game-results .word-letters .letter-card{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    height: 25px;
    outline: 1px solid #fff;
    width: 25px;
    font-size: 18px;
}
#modal-stats .share{
    margin-top: 10px;
}
#modal-stats .share-buttons{
    display: flex;
    gap: 5px;
    margin-top: 10px;
}
.share{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #D40C88;
    font-size: 0.8em;
    padding: 10px;
    width: 100%;
    max-width: 150px;
    border-radius: 5px;
    cursor: pointer;
}
.share svg{
    margin-right: 15px;
}

// Tes Stats
#modal-stats .game-stats{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
#modal-stats .game-stats .modal-title{
    color: #D40C88;
    font-size: 1em;
    margin-bottom: 12px;
}
#modal-stats .game-stats .average{
    color: yellow;
}
#modal-stats .game-stats .graph{
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    background-color: #092d4e;
    padding: 5px;
}
#modal-stats .game-stats .graph .graph-card{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    font-size: 0.6em;
    min-height: 100%;
    margin: 0 auto;
}
#modal-stats .game-stats .graph .graph-card .column{
    background-color: #D40C88;
    min-width: 10px;
    width: calc(100%/7);
    margin: 0 auto;
}
#modal-stats .game-stats .graph .graph-card .try{
    text-align: center;
}
#modal-stats .game-stats .graph .graph-card .count{
    color: #02A2DF;
    text-align: center;
    line-height: 1;
}

// All Games records
#modal-stats .all-games-records{
    display: flex;
    flex-direction: column;
    align-items: center;
}
#modal-stats .all-games-records .modal-title{
    color: #D40C88;
}
#modal-stats .all-games-records select{
    font-size: 1em;
    text-align: center;
    width: 100%;
    padding: 10px 0;
    margin: 10px 0;
    border-radius: 5px;
}
#modal-stats .all-games-records .share{
    margin-top: 15px;
}
/*Header*/
header{
    width: 100%;
    min-height: 60px;
    display: flex;
    justify-content: center;
    background: #0D4678;
    font-size: 1.1em;
    padding: 0 5px;
}
.header-container{
    max-width: 375px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
}
.header-left, .header-right{
    display: flex;
    width: 68px;
    justify-content: space-between;
}
.header-middle{
    display: flex;
    justify-content: center;
    width: 50%;
}
.motuz-title{
    display: flex;
    flex-direction: row;
}
.motuz-title #motuz-logo{
    width: 100%;
    height: 1.8em;
    box-shadow: 2px 3px 2px 1px rgba(0, 0, 0, 0.495);
}
.motuz-title span{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    box-shadow: 2px 3px 2px 1px rgba(0, 0, 0, 0.495);
    margin: 0 1px;
    font-size: 1.5em;
}
.motuz-title .correct-title{
    background-color: #D40C88;
}
.motuz-title .incorrect-title{
    background-color: #00ACEE;
}
.motuz-title .tilt{
    float:left;
    -webkit-transform: rotate(10deg);
}
.motuz-title img{
    height: 70%;
    width: auto;
}
.icon-button{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: inherit;
    outline: 1px solid #fff;
    cursor: pointer;
}
.icon-button:hover{
    outline: 2px solid #fff;
}

@media (max-width: 350px){
    header {
        font-size: 16px;
    }
    .motuz-title span{
        height: 26px;
        width: 26px;
    }
    .icon-button{
        width: 26px;
        height: 26px;
    }
    .header-left, .header-right{
        width: 55px;
    }
}
/*Game Container*/
.game-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    width: 100%;
    height: 100vh;
}
/*Footer*/
footer{
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    width: 100%;
    background-color: #0D4678;
    font-size: 1.1em;
}
footer span{
    margin-right: 0.5em;
}
.triangle{
    color: yellow;
}
.rotated-270 {
    float:left;
    -webkit-transform: rotate(270deg);
}

@media screen and (orientation:portrait) {
    @media (min-width:0px) and (max-width: 520px) { /* smartphones, iPhone, portrait 480x320 phones */ 
        
    }
}
@media screen and (orientation:landscape) {
    @media (min-height:0px) and (max-height: 680px) { /* smartphones, iPhone, portrait 480x320 phones */ 
        .game-container{
            height: 100%;
        }
    }
}