#daily .title-container{
    display: flex;
    align-items: center;
}
#daily .title-container .msg{
    font-size: 0.8em;
    opacity: 1;
    margin-left: 10px;
}
#daily .title-container .msg .err{
    color: rgb(255, 146, 146);
}
#daily .element-page{
    padding: 0;
    border: none;
}
#daily .cards-container{
    display: flex;
    padding: 10px;
}
#daily .cards-container .card{
    box-shadow: 2px 2px 2px 2px lightgray;
    border-radius: 10px;
    border: none;
    background: inherit;
    font-size: 0.7em;
    width: auto;
    margin: 0 5px;
    padding: 10px;
    cursor: pointer;
}
#daily .cards-container .card:hover{
    box-shadow: 2px 2px 2px 1px lightgray;
}
#daily .cards-container .selected-card{
    border-radius: 10px;
    border: none;
    background: #000;
    color: #fff;
    font-size: 0.7em;
    width: auto;
    margin: 0 5px;
    padding: 10px;
    cursor: pointer;
}
#daily .selection{
    display: flex;
    background-color: lightgray;
    padding: 2px 10px;
    border-radius: 5px 5px 0 0;
    color: #fff;
    font-size: 2em;
}
#daily .selection span{
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-size: 20px;
}
#daily .selection span:hover{
    color: #000;
    cursor: pointer;
}
#daily .selection .current{
    font-size: 22px;
}
#daily .month-header, #daily .year-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    color: #fff;
}
#daily .year-selection, #daily .month-selection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px 5px 0 0;
    color: #fff;
    font-size: 1.3em;
}
#daily .year-selection span:hover{
    color: #000;
}
#daily .year-header .year, #daily .month-header .month{
    font-size: 1em;
    letter-spacing: 3px;
    margin: 0 5px;
}
#daily .month-loader{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 338px;
}
#daily .update-buttons{
    display: flex;
    align-items: center;
    padding: 10px 0;
    background-color: lightgray;
}
#daily .update-buttons button{
    margin: 0 10px;
    font-size: 13px;
}
#daily .month-display{
    display: flex;
    align-items: center;
    justify-content: center;
}
#daily .month-body{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 1px;
}
#daily .day-card{
    display: flex;
    flex-direction: column;
    width: calc(100% / 7 - 2px);
    height: 55px;
    border: none;
    background-color: lightgray;
    padding: 1px 3px;
    margin: 1px;
}
#daily .day-card .day-top{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 0.6em;
}
#daily .day-card .day-number{
    width: fit-content;
    text-align: left;
}
#daily .day-card .day-word{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 0.8em;
    text-align: center;
}
#daily .day-card .circle{
    border-radius: 50%;
    margin: 1px;
    padding: 1px 2px;
    width: 19px;
    height: 17px;
    border-radius: 50%;
    line-height: 15px;
    font-size: 10px;
}
#daily .day-card .day-number-today{
    background-color: #000;
    color: #fff;
    cursor: help;
}
#daily .day-card .day-number-lastUpdate{
    background-color: rgb(37, 54, 238);
    color: #fff;
    cursor: help;
}
#daily .day-card .day-number-server{
    background-color: rgb(164, 145, 39);
    color: #fff;
    cursor: help;
}
