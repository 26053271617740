$background: rgb(0, 0, 0);
$headerText: #fff;

$backgroundNavBar: rgba(100, 100, 100, 0.251);
$backgroundNavButton: #fff;

$softSecondaryColor: #eaeaea;
$secondaryColor: rgba(217, 205, 235, 0.7);
#profile{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 20px;
    color: rgb(60, 60, 60);
}
#profile header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $background;
    color: $headerText;
    padding: 2vh 2vw;
    height: 10%;
}
#profile .logo{
    font-size: 1.5rem;
}
#profile .non-logo{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
#profile .non-logo .avatar{
    display: flex;
    align-items: center;
}
#profile .non-logo .avatar img{
    width: 35px;
    height: 35px;
    border-radius: 2px;
    cursor: pointer;
}
#profile .profile-body{
    display: flex;
    width: 100%;
    height: 90%;
}
#profile .page{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-bottom: 0;
    overflow: auto;
}
#profile .page > div{
    min-width: 700px;
    min-height: 600px;
}
#profile #sidebar{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 150px;
    background-color: $backgroundNavBar;
    padding: 10px;
}
#profile .nav-button{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 100px;
    color: #000;
    border-radius: 10px;
    border: none;
    font-size: 0.9rem;
    cursor: pointer;
    margin: 0.8em 0;
}
#profile .nav-button:hover{
    background-color: #fff;
}
@media (max-width: 550px){
    #profile #sidebar{
        width: 60px;
        font-size: 0.6em;
        padding: 10px 0;
    }
    #profile #sidebar .nav-button{
        font-size: 1em;
        width: 100%;
        border-radius: 0;
        padding: 10px 15px;
    }
}
#profile .title-container .title{
    font-size: 1.2em;
}
#profile section{
    padding: 1em;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px rgba(192, 192, 192, 0.6);
}
#profile .table-row-data:hover{
    background-color: inherit;
}
#profile .table-row-data:hover{
    background-color: $softSecondaryColor;
}
#profile .clickable{
    cursor: pointer;
    margin: 0 0.2em;
}
#profile .user-panel{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: calc(100vh - 50px - 100px);
    position: absolute;
    top: 50px;
    right: 8px;
    background: white;
    box-shadow: 0 2px 10px rgba(0,0,0,.2);
    border: 2px inset lightgray;
    min-height: 50px;
    height: 130px;
    width: 150px;
    border-radius: 0.1rem; 
    z-index: 1000;
    overflow-y: auto;
    overflow-x: none;
}
#profile .user-panel .title{
    padding: 0.6rem;
    color: #fff;
    background-color: black;
    width: 100%;
}
#profile .user-panel .list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90%;
    width: 90%;
}