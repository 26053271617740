#general .title-container{
    display: flex;
    align-items: center;
}
#general .title-container button{
    margin: 0 10px;
}
#general .title-container .msg{
    font-size: 0.8em;
    opacity: 1;
    margin-left: 10px;
}
#general .title-container .msg .err{
    color: rgb(255, 146, 146);
}
#general form{
    width: 50%;
    font-size: 16px;
}
#general .input-wrap{
    display: flex;
    align-items: center;
    padding: 0 0.2em;
    margin: 1em 0;
    font-size: 1em;
    width: 100%;
}
#general .input-wrap label{
    font-size: 1em;
    width: 7em;
}

#avatar{
    width: 7em;
    height: 7em;
    border-radius: 5px;
    cursor: pointer;
}

#avatar:hover{
    outline: 2px solid #000;
}

#general #avatar-input{
    position: absolute;
    opacity: 0;
    display: none;
}
#general input{
    cursor: pointer;
}
#general input[type=text]:hover, #general input[type=file]:hover{
    background-color: rgb(235, 235, 235);
}
#general input:disabled{
    cursor: auto;
}
#general input:disabled:hover{
    background-color: lightgray;
}