*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 100%;
}
a{
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
a:hover{
  opacity: 0.7;
}
.App{
  font-family: Outfit,Avenir,Helvetica,Arial,sans-serif;
  text-align: center;
  color: #919191;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px;
}

/*Shortcuts */
.ce-cont{
  align-items: center;
  justify-content: center;
}
.align-ce{
  align-items: center;
}
.flexcol{
  display: flex;
  flex-direction: column;
}
.flexrow{
  display: flex;
  flex-direction: row;
}
.hcenter{
  width: 100%;
  text-align: center;
}
.ov-auto{
  height: 400px;
  overflow-y: auto;
}
.just-text{
  text-align: justify;
}
.w100{
  width: 100%;
}
.pointer{
  cursor: pointer;
}
.bold-text{
  font-weight: 800;
  opacity: 1;
}
.light-text{
  opacity: 0.5;
}
.margin-h-auto{
  margin: 0 auto;
}
#bubble{
  display: none;
  background-color: rgb(37, 37, 37);
  border-radius: 20px;
  padding: 10px;
  color: white;
  position: absolute;
  z-index: 5000;
  opacity: 1;
  animation-name: fadeOutOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 3s;
}